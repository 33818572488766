@import 'bootstrap.less';

@import '../../node_modules/font-awesome/less/font-awesome.less';
@import '../../node_modules/handsontable/dist/handsontable.css';
@import '../../node_modules/@timeedit/ui-components/lib/src/assets/styles/styles.css';
@import '../../node_modules/pikaday/css/pikaday.css';
@import '../../node_modules/quill/dist/quill.bubble.css';
@import '../../node_modules/quill/dist/quill.snow.css';

@import 'variables.less';
@import 'mixins.less';

@import 'imports.less';
@import 'main.less';

// Tailwind applies a global reset that can override our custom styles. Using 'revert' restores
// the styles to their default or previously defined values, ensuring we retain intended styles.
ul, ol {
  list-style: revert;
  padding: revert;
}

img {
  width: revert;
  max-width: revert;
}
